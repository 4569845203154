import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
  TemplateRef,
} from "@angular/core";
import MetisMenu from "metismenujs/dist/metismenujs";
import { EventService } from "../../core/services/event.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { HttpClient } from "@angular/common/http";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { EcommerceService } from "../../pages/ecommerce/ecommerce.service";
import Swal from "sweetalert2";
import { first } from "rxjs/operators";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { RegisterUserDto } from "src/app/pages/customer/customer.model";

interface User {
  customerId: number;
  fullName: string;
  email: string;
  productName: string;
  quantity: number;
  deliveryAddress: string;
  additionalInformation: string;
  phoneNumber: string;
}

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("componentRef") scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  isLoggedIn = false;
  username = "";
  cart = [];
  submitted = false;
  isLoading = false;
  searchResult: any[] = [];
  submitted2 = false;
  registerForm: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  isLoading2 = false;
  searchQuery: string = "";
  enabled: boolean = false;

  error2 = "";

  obj = {
    email: null,
    password: null,
  };
  success = "";
  error = "";

  registrationForm = {
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    password: null,
    confirmPassword: null,
    country: null,
    state: null,
    lga: null,
    city: null,
    address: null,
  };

  countryList = [{ value: "Nigeria", label: "Nigeria" }];
  stateList = [{ value: "Abuja", label: "Abuja" }];
  lgaList = [{ value: "Abuja East", label: "Abuja East" }];

  currentUser: any = null;

  isLinkOpen = false;

  // sidebarItems: any = [];
  menuItems = [];
  productCategories = [
    {
      id: 10,
      name: "AGRICULTURE",
      icon: "Tree",
      subItems: [
        {
          id: 6,
          name: "Seasoning",
          icon: "leaf",
          subitems: [
            {
              id: 19,
              name: "Tomato",
              icons: " squarespace",
            },
            {
              id: 20,
              name: "Bell Pepper",
              icons: " squarespace",
            },
            {
              id: 21,
              name: "Habenaro",
              icons: " squarespace",
            },
            {
              id: 22,
              name: "Onion",
              icons: " squarespace",
            },
          ],
        },
        {
          id: 7,
          name: "Vegetables",
          icon: "leaf",
          subitems: [
            {
              id: 23,
              name: "Tomato",
              icons: " squarespace",
            },
            {
              id: 24,
              name: "Bell Pepper",
              icons: " squarespace",
            },
            {
              id: 25,
              name: "Habenaro",
              icons: " squarespace",
            },
            {
              id: 26,
              name: "Cucumber",
              icons: " squarespace",
            },
            {
              id: 27,
              name: "Green peas",
              icons: " squarespace",
            },
            {
              id: 28,
              name: "Cabbage",
              icons: " squarespace",
            },
            {
              id: 29,
              name: "Lettuce",
              icons: " squarespace",
            },
            {
              id: 30,
              name: "Spinnach",
              icons: " squarespace",
            },
            {
              id: 31,
              name: "Fluted pumpkin leaves (Ugu)",
              icons: " squarespace",
            },
            {
              id: 32,
              name: "Moringer",
              icons: " squarespace",
            },
            {
              id: 33,
              name: "Bitter leaf",
              icons: " squarespace",
            },
            {
              id: 34,
              name: "Baobab",
              icons: " squarespace",
            },
          ],
        },
        {
          id: 3,
          name: "Cereals",
          icon: "bowl-rice",
          subitems: [
            {
              id: 3,
              name: "Rice",
              icons: "bowl-rice",
            },
            {
              id: 4,
              name: "Sorghum",
              icons: "bowl-hot",
            },
            {
              id: 5,
              name: "Millet",
              icons: "bowl-hot",
            },
            {
              id: 6,
              name: "Maize",
              icons: "bowl-hot",
            },
            {
              id: 7,
              name: "Wheat",
              icons: "bowl-hot",
            },
            {
              id: 8,
              name: "Sesame",
              icons: "bowl-hot",
            },
            {
              id: 9,
              name: "Tigeer Nut",
              icons: "bowl-hot",
            },
            {
              id: 10,
              name: "Acha (Fonio)",
              icons: "bowl-hot",
            },
          ],
        },
        {
          id: 5,
          name: "Spices Updated",
          icon: "lemon",
          subitems: [
            {
              id: 11,
              name: "Ginger",
              icons: "leaf",
            },
            {
              id: 12,
              name: "Cloves",
              icons: "leaf",
            },
            {
              id: 13,
              name: "Pepper",
              icons: "leaf",
            },
            {
              id: 14,
              name: "Garlic",
              icons: "leaf",
            },
            {
              id: 15,
              name: "Turmetic",
              icons: "leaf",
            },
            {
              id: 16,
              name: "Cumin",
              icons: "leaf",
            },
            {
              id: 17,
              name: "Nutmeg",
              icons: "leaf",
            },
            {
              id: 18,
              name: "Cinnamon",
              icons: "leaf",
            },
          ],
        },
      ],
    },
    {
      id: 11,
      name: "PROCESSED PRODUCT",
      icon: "package",
      subItems: [],
    },
    {
      id: 12,
      name: "WATER",
      icon: "water",
      subItems: [],
    },
    {
      name: "Agriculture",
      icon: "",
      subItems: [
        {
          name: "Cereals",
          icon: "",
          subItems: [
            {
              name: "Millet",
            },
          ],
        },
      ],
    },
  ];

  user: User = {
    customerId: this.currentUser ? this.currentUser : null,
    fullName: "",
    email: "",
    productName: "",
    quantity: 0,
    deliveryAddress: "",
    phoneNumber: "",
    additionalInformation: "",
  };

  @ViewChild("sideMenu") sideMenu: ElementRef;
  @ViewChild("loginModal") loginModalRef: TemplateRef<any>;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient,
    private readonly ecommerceService: EcommerceService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });

    // this.getProductsCategory();
    const menuItems = JSON.parse(localStorage.getItem("menuItems"));
    // const menuItems = localStorage.getItem("menuItems");
    this.menuItems = menuItems?.sort((a, b) => a.id - b.id);
    console.log("🚀 ~ SidebarComponent ~ menuItems:", this.menuItems);
  }

  //submit ordered products

  submitManualOrder() {
    if (
      this.user.fullName &&
      this.user.email &&
      this.user.productName &&
      this.user.quantity &&
      this.user.deliveryAddress &&
      this.user.phoneNumber &&
      this.user.additionalInformation
    ) {
      this.ecommerceService.createManualOrder(this.user).subscribe(
        (response: any) => {
          console.log("Order response:", response);

          // Clear the form
          this.user = {
            customerId: this.currentUser ? this.currentUser : null,
            fullName: "",
            email: "",
            productName: "",
            quantity: 0,
            deliveryAddress: "",
            phoneNumber: "",
            additionalInformation: "",
          };

          // Show success alert
          Swal.fire({
            icon: "success",
            title: "Order Submitted",
            text: response.message,
            confirmButtonText: "OK",
          });
        },
        (error) => {
          console.error("Error submitting order:", error);

          // Show error alert
          Swal.fire({
            icon: "error",
            title: "Submission Failed",
            text: "There was an error submitting your order. Please try again.",
            confirmButtonText: "OK",
          });
        }
      );
    } else {
      // Optionally show an alert if form validation fails
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill in all required fields.",
        confirmButtonText: "OK",
      });
    }
  }

  isActive(item): boolean {
    return this.router.isActive(item.link, {
      paths: "exact",
      queryParams: "ignored",
      fragment: "ignored",
      matrixParams: "ignored",
    });
  }

  showAuthModal(content: any) {
    this.router.navigate(["/account/login"]);
  }

  openLoginModal(content: TemplateRef<any>) {
    this.modalService
      .open(content, {
        centered: true,
        size: "md",
        animation: true,
        backdrop: "static",
        keyboard: false,
      })
      .result.then((result) => {
        console.log("Modal closed" + result);
      })
      .catch((res) => {});
  }

  ngOnInit() {
    this.username = JSON.parse(localStorage.getItem("currentUser"))?.fullName;
    this.enabled = JSON.parse(localStorage.getItem("currentUser"))?.enabled;

    this.initialize();
    // this.menuItems = MENU;
    // this.menuItems = JSON.parse(localStorage.getItem('menuList'));
    this._scrollElement();
    // this.getProductsCategory();
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const isLoggedIn = localStorage.getItem("isLoggedIn");
    this.isLoggedIn = JSON.parse(isLoggedIn) != null ? true : false;

    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  hamdleShowRegisterModal(content: any) {
    this.modalService.dismissAll();
    setTimeout(() => {
      this.modalService.open(content, { centered: true, size: "lg" });
    }, 200);
  }

  onSearch(category: string) {
    if (category.trim()) {
      this.isLoading = true;
      this.ecommerceService.searchProduct(category).subscribe(
        (response: any) => {
          this.searchResult = response.data;
          if (this.searchResult.length === 0) {
            this.router.navigate(["app/ecommerce/search"], {
              queryParams: {
                query: category,
                outOfStockMessage: `${category} is out of stock`,
              },
            });
          }

          this.isLoading = false;
          // Redirect to search results page
          this.router.navigate(["/app/ecommerce/search"], {
            queryParams: { query: category },
          });
        },
        (error) => {
          console.error("Search error:", error);
          this.isLoading = false;
        }
      );
    }
  }

  //get products category
  getProductsCategory() {
    this.ecommerceService.getProductsCategory().subscribe(
      (response: any) => {
        console.log("sidebar items :", response.data);
        this.menuItems = response.data;
      },
      (error) => {
        console.log(
          "🚀 ~ HomeComponent ~ this.ecommerceService.getAllProducts ~ error:",
          error
        );
      }
    );
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();

    const drawerCloseButton = document.querySelector(
      '[data-dismiss="drawer"]'
    ) as HTMLButtonElement;
    if (drawerCloseButton) {
      drawerCloseButton.addEventListener("click", this.closeDrawer.bind(this));
    }

    const drawerOpenButton = document.querySelector(
      '[data-toggle="drawer"]'
    ) as HTMLButtonElement;
    if (drawerOpenButton) {
      drawerOpenButton.addEventListener("click", this.openDrawer.bind(this));
    }
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle("mm-show");
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0]["offsetTop"];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass("mm-active");
    this._removeAllClass("mm-show");
    const links = document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add("active");
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add("mm-active");
        const parent2El = parentEl.parentElement.closest("ul");
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== "side-menu") {
            parent3El.classList.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");
            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== "side-menu") {
              parent4El.classList.add("mm-show");
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== "side-menu") {
                parent5El.classList.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = this.menuItems;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    // return item.subItems !== undefined ? item.subItems.length > 0 : false;
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
  // }

  /**
   * Open modal
   * @param content modal content
   */
  assignApiModal(content: any) {
    this.modalService.open(content, {
      centered: true,
      size: "md",
      keyboard: true,
      backdrop: "static",
    });
  }

  logout() {
    this.authenticationService.logout();
  }

  formatLink(categoryName: string) {
    // return String(categoryName.replace(/ /g, "-")).toLowerCase();
    return String(categoryName).toLowerCase();
  }

  navigateTo(url: string): void {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }

  navigatingPane(route: string, tab: string) {
    this.router.navigate([route], { queryParams: { tab } });
  }

  logoutUser() {
    this.authenticationService.logout();

    if (this.router.url.includes("profile")) {
      this.authenticationService.logout();
      this.router.navigate(["/"]);
    } else {
      this.authenticationService.logout();
      window.location.reload();
    }
  }

  closeDrawer() {
    const drawer = document.getElementById("orderModal");
    if (drawer) {
      drawer.classList.remove("show");
      drawer.style.width = "0";

      const orderButton = document.querySelector(
        '[data-toggle="modal"]'
      ) as HTMLButtonElement;
      console.log("orderButton ::", orderButton);
      if (orderButton.disabled) {
        orderButton.disabled = false;
      }
    }

    // Remove the backdrop
    const backdrop = document.querySelector(
      ".modal-backdrop.show"
    ) as HTMLElement;
    const backdropFade = document.querySelector(
      ".modal-backdrop.fade"
    ) as HTMLElement;
    if (backdrop) {
      backdrop.classList.remove("show");
      backdrop.style.opacity = "0";
    }

    if (backdropFade) {
      backdropFade.classList.remove("show");
      backdropFade.style.display = "none";
    }
    // Remove the background-color property from elements with the 'drawer' class
    const drawerElements = document.getElementsByClassName("drawer");
    for (let i = 0; i < drawerElements.length; i++) {
      const element = drawerElements[i] as HTMLElement;
      element.style.backgroundColor = "";
    }
  }

  // Function to open the drawer
  openDrawer() {
    const drawer = document.getElementById("orderModal");
    if (drawer) {
      drawer.classList.add("show");
      drawer.style.width = "800px";
    }

    // Show the backdrop with 'show' class
    const backdrop = document.querySelector(".modal-backdrop") as HTMLElement;
    if (backdrop) {
      backdrop.classList.add("show");
      backdrop.style.opacity = "0.5";
    }

    // Show the backdrop with 'fade' class
    const backdropFade = document.querySelector(
      ".modal-backdrop.fade"
    ) as HTMLElement;
    if (backdropFade) {
      backdrop.classList.add("show");
      backdropFade.style.display = "block";
    }
  }

  toggleLink() {
    this.isLinkOpen = !this.isLinkOpen;
  }

  redirectToPage(route: string) {
    this.router.navigateByUrl(route);
  }

  onSubmit() {
    this.submitted = true;
    const userRegisterDto = {
      username: this.obj.email,
      password: this.obj.password,
    };
    this.authenticationService
      .login(userRegisterDto)
      .pipe(first())
      .subscribe(
        (data) => {
          this.success = "Login successful";
          this.error = "";
          setTimeout(() => {
            this.modalService.dismissAll();
          }, 300);
          window.location.reload();
          // this.router.navigate(["/dashboard"]);
        },
        (error) => {
          this.error = error ? error : "";
        }
      );
  }

  onRegister() {
    this.submitted2 = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    } else {
      console.log("registerForm__form ==>>", this.registerForm.value);

      const registerUserDto: RegisterUserDto = {
        username: String(
          `@${this.registerForm.get("firstname").value}-${
            this.registerForm.get("lastname").value
          }`
        ).toLowerCase(),
        password: this.registerForm.get("password").value,
        email: this.registerForm.get("email").value,
        fullName: `${this.registerForm.get("firstname").value} ${
          this.registerForm.get("lastname").value
        }`,
        phoneNumber: this.registerForm.get("phone").value,
        country: this.registerForm.get("country").value,
        state: this.registerForm.get("state").value,
        lga: this.registerForm.get("lga").value,
        cityOrTown: this.registerForm.get("city").value,
        address: this.registerForm.get("address").value,
      };
      console.log(
        "🚀 ~ TopbarComponent ~ onRegister ~ registerUserDto:",
        registerUserDto
      );

      // call registration service
      this.authenticationService
        .register(registerUserDto)
        .pipe(first())
        .subscribe(
          (response) => {
            console.log(
              "🚀 ~ TopbarComponent ~ onRegister ~ response:",
              response
            );
            // this.router.navigate(["/dashboard"]);
            this.modalService.dismissAll();
            this.loginForm
              .get("email")
              .setValue(this.registerForm.get("email").value);
            this.loginForm
              .get("password")
              .setValue(this.registerForm.get("password").value);
            this.openLoginModal(this.loginModalRef);
            this.registerForm.reset();
          },
          (error) => {
            this.error2 = error ? error : "";
          }
        );
    }
  }

  pdfPath = "assets/documents/catalogue.pdf";

  downloadPdf() {
    // Create an anchor element
    const link = document.createElement("a");

    // Set the href to the PDF path
    link.href = this.pdfPath;

    // Set the download attribute with desired filename
    link.download = "downloaded-document.pdf";

    // Set the target to _blank to support different browsers
    link.target = "_blank";

    // Append to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up - remove the element
    document.body.removeChild(link);
  }
}
