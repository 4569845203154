<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!-- Sidemenu -->
  <div id="sidebar-menu" class="sidebar-nav">
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu style="flex-grow: 1;">
      <!-- <li class="menu-title">Agriculture 2</li> -->
      <ng-container *ngFor="let item of menuItems">
        <li>
          <!-- Parent menu item with or without subItems -->
          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent" [ngClass]="{
              'has-arrow': !item?.badge,
              'has-dropdown': item?.badge
            }" routerLinkActive="active">
            <!-- <i class="bx bx-{{ item?.icon }}" style="color: #000" *ngIf="item?.icon"></i> -->
            <img src="../../../assets/images/icons/{{item?.icon}}.png" width="12%" height="12%"
              style="margin-right: 3%;" />
            <span style="color: #000"> {{ item?.name | translate }}</span>
          </a>

          <!-- Direct link to a route without subItems  -->
          <a routerLink="/app/ecommerce/product-category/{{
              formatLink(item?.name)
            }}" *ngIf="!hasItems(item)" class="side-nav-link-ref">
            <!-- <i class="bx bx-{{ item?.icon }}" *ngIf="item?.icon"></i> -->
            <img src="../../../assets/images/icons/{{item?.icon}}.png" width="12%" height="12%"
              style="margin-right: 3%;" />
            <span> {{ item?.name | translate }}</span>
            <span class="badge rounded-pill bg-{{ item?.badge?.variant }} float-end" *ngIf="item?.badge">
              {{ item?.badge?.text | translate }}
            </span>
          </a>

          <!-- Submenu items -->
          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <!-- Direct link to a route -->
              <a routerLink="/app/ecommerce/product-category/{{
                  formatLink(item?.name)
                }}" *ngIf="!hasItems(subitem)" class="side-nav-link-ref" [attr.data-parent]="subitem?.parentId"
                [ngClass]="{
                  'inactive-item': !isActive(subitem),
                  'active-item': isActive(subitem)
                }" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                style="font-family: Nunito Sans, sans-serif">
                <!-- {{ item | json }} -->
                <!-- <i class="bx bx-{{ item?.icon }}" style="color: #000" *ngIf="item?.icon"></i> -->
                <img src="../../../assets/images/icons/{{subitem?.icon}}.png" width="12%" height="12%"
                  style="margin-right: 3%;" />
                {{ subitem?.name }}
              </a>

              <!-- Submenu items with dropdown -->
              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                [attr.data-parent]="subitem?.parentId">
                <!-- <i class="bx bx-{{ item?.icon }}" style="color: #000" *ngIf="item?.icon"></i> -->
                <img src="../../../assets/images/icons/{{subitem?.icon}}.png" width="12%" height="12%"
                  style="margin-right: 3%;" />
                {{ subitem?.name }}
              </a>
              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems" style="cursor: pointer; margin-left: 15%;">
                  <!-- Direct link to a route -->
                  <a (click)="navigateTo('/app/ecommerce/product-sub-category/' + formatLink(subSubitem?.name))"
                    [attr.data-parent]="subSubitem?.parentId" [ngClass]="{
                       'inactive-item': !isActive(subSubitem),
                       'active-item': isActive(subSubitem)
                     }" class="side-nav-link-ref">
                    {{ subSubitem?.name | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>


    <div class="d-flex flex-column d-md-none">


      <form class="app-search search-div d-md-none" (ngSubmit)="onSearch(searchQuery)"
        [ngStyle]="{ 'margin-top': !enabled && isLoggedIn ? '30px' : '' }"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center; margin: 0 10px;">
        <div class="position-relative" style="
          display: flex;
          gap: 10px;
          border: 1px solid gray;
          border-radius: 20px;
          background-color: transparent;
          position: relative;
        ">
          <input type="text" class="form-control search-input" placeholder="Search product..." [(ngModel)]="searchQuery"
            name="searchQuery" style="
              background-color: transparent;
              color: #000000;
              padding-right: 40px;
            " />
          <button type="submit" style="
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            cursor: pointer;
            padding: 5px;
          ">
            <i class="bx bx-search" style="font-size: 20px; color: gray;"></i>
          </button>
        </div>
        <p *ngIf="!enabled && isLoggedIn"
          style="color: red; background-color: transparent; text-align: center; margin-top: 10px; padding: 5px;">You
          have not verified
          your account <a href="/account/verify-otp">Click
            here to verify</a></p>
      </form>



      <!-- <div class="dropdown d-md-none"></div> -->


    </div>

    <div style="padding: 10px;">
      <button style="background-color: transparent; border: none;">Can&apos;t find your preferred product? Click the
        link below</button>

      <div style="display: flex; justify-content: center;">
        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#orderModal">Order
          products</button>
      </div>

    </div>

    <div style="padding: 10px;">
      <ul style="list-style: none;">
        <li>


          <div class="pdf-download-container">
            <button (click)="downloadPdf()"
              style="border: none; background: transparent; color: #0D8140; text-align: center; font-weight: bold; margin-left: 20px;">
              Download PDF
            </button>
          </div>
        </li>
      </ul>

    </div>


    <div class="d-flex align-items-center">
      <div *ngIf="isLoggedIn" class="dropdown d-inline-flex align-items-center" ngbDropdown [placement]="'top-start'">
        <button type="button" class="btn header-item" style="display: flex; flex-direction: row; align-items: center">
          <div class="dropdown d-inline-block">
            <button type="button" class="btn header-item" ngbDropdownToggle
              style="display: flex; flex-direction: row; align-items: center">
              <i class="bx bx-user" style="font-size: 16px; color: #000000"></i>
              <!-- <span class="d-md-none ms-1" style="font-size: 14px; color: #000000">{{ username }}</span> -->
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item"
                (click)="navigatingPane('/app/ecommerce/profile', 'profile')">Profile</button>
              <button class="dropdown-item" (click)="navigatingPane('/app/ecommerce/profile', 'orders')">My
                Orders</button>
              <button class="dropdown-item"
                (click)="navigatingPane('/app/ecommerce/profile', 'settings')">Settings</button>
            </div>
          </div>
        </button>
      </div>


      <div class="dropdown  d-flex align-items-center d-md-none">
        <button type="button" class="btn header-item noti-icon"
          style="display: flex; flex-direction: row; align-items: center" id="page-header-notifications-dropdown"
          (click)="redirectToPage('/app/ecommerce/cart')">
          <i class="bx bx-cart-alt d-md-none" style="font-size: 16px; color: #000000"></i>
          <!-- <span class="d-md-none ms-1" style="font-size: 14px; color: #000000">Cart</span> -->

          <span class="badge bg-danger rounded-pill" style="left: 20px !important; right: unset;">{{
            cart != null ?
            cart?.length : 0
            }}</span>
          <span class="d-none d-xl-inline-block ms-1" style="font-size: 14px; color: #000000">Cart
          </span>
        </button>
      </div>

      <div *ngIf="!isLoggedIn" class="dropdown d-md-none" ngbDropdown style="margin-left: 12px;">
        <button type="button" class="btn header-item d-md-none" ngbDropdownToggle id="page-header-user-dropdown"
          style="display: flex; flex-direction: row; align-items: center; color: #000;"
          (click)="showAuthModal(loginModal)"><i class="bx bx-log-out" style="font-size: 16px; color: #000000"></i>
          <span class="d-md-none ms-1" style="font-size: 14px; color: #000000">Log in</span></button>

      </div>

      <div *ngIf="isLoggedIn" class="dropdown d-md-none" ngbDropdown style="margin-left: 12px;">
        <button type="button" class="btn header-item d-md-none" ngbDropdownToggle id="page-header-user-dropdown"
          style="display: flex; flex-direction: row; align-items: center; color: #000;" (click)="logout()"><i
            class="bx bx-log-out" style="font-size: 16px; color: #000000"></i> <span class="d-md-none ms-1"
            style="font-size: 14px; color: #000000">Logout</span></button>
      </div>
    </div>



  </div>
</ng-template>


<ng-template #loginModal role="document" let-modal>
  <div class="modal-body">
    <div class="card overflow-hidden">
      <div class="bg-soft bg-primary">
        <div class="row">
          <div class="col-7">
            <div class="text-primary p-4">
              <h5 class="text-primary">Welcome Back !</h5>
              <p>Sign in to continue to Shoplife.</p>
            </div>
          </div>
          <div class="col-5 align-self-end">
            <img src="assets/images/profile-img.png" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div>
          <a routerLink="/">
            <div class="avatar-md profile-user-wid mb-4">
              <span class="avatar-title rounded-circle bg-light">
                <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34" />
              </span>
            </div>
          </a>
        </div>
        <div class="p-2">
          <form class="form-horizontal" (ngSubmit)="onSubmit()">
            <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
              error
              }}</ngb-alert>
            <ngb-alert type="success" *ngIf="success" [dismissible]="false">{{
              success
              }}</ngb-alert>

            <div class="mb-3">
              <label for="email">Username/Email</label>
              <input type="text" class="form-control" [class.is-invalid]="email.invalid &amp;&amp; email.touched"
                name="email" #email="ngModel" [(ngModel)]="obj.email" placeholder="Enter email" required />
              <div *ngIf="email.invalid &amp;&amp; email.touched">
                <small *ngIf="email.errors?.required" class="text-danger">Email is Required</small>
              </div>
            </div>

            <div class="mb-3">
              <label for="password">Password</label>
              <div class="input-group auth-pass-inputgroup">
                <input type="password" class="form-control"
                  [class.is-invalid]="password.invalid &amp;&amp; password.touched" name="password" #password="ngModel"
                  [(ngModel)]="obj.password" placeholder="Enter password" required />

                <button class="btn btn-light ms-0" type="button" id="password-addon">
                  <i class="mdi mdi-eye-outline"></i>
                </button>

                <div *ngIf="password.invalid &amp;&amp; password.touched">
                  <small *ngIf="password.errors?.required" class="text-danger">Password is Required</small>
                </div>
              </div>
            </div>

            <div style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  ">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="remember-check" />
                <label class="form-check-label" for="remember-check">
                  Remember me
                </label>
              </div>

              <div class="text-center">
                <a routerLink="/account/reset-password" class="text-muted"><i class="mdi mdi-lock mr-1"></i> Forgot
                  your password?</a>
              </div>
            </div>

            <div class="mt-3 d-grid">
              <button *ngIf="!isLoading" class="btn btn-primary" type="submit">
                Log In
              </button>
              <button *ngIf="isLoading" class="btn btn-primary" type="submit">
                Logging In <span><i class="bx bx-loader bx-spin"></i></span>
              </button>
            </div>

            <!-- <div class="mt-4 text-center">
                    Don't have an account?<a
                      (click)="toggleAuth()"
                      style="margin-left: 0.5rem; cursor: pointer"
                      class="text-muted"
                      >Create One</a
                    >
                  </div> -->
            <div class="mt-4 text-center">
              Don't have an account?<a (click)="hamdleShowRegisterModal(showRegisterModal)"
                style="margin-left: 0.5rem; cursor: pointer" class="text-muted">Create One</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #showRegisterModal role="document" let-modal>
  <div class="modal-body">
    <div class="card overflow-hidden">
      <div class="bg-soft bg-primary">
        <div class="row">
          <div class="col-7">
            <div class="text-primary p-4">
              <h5 class="text-primary">Welcome Back !</h5>
              <p>Sign in to continue to API Dashboard.</p>
            </div>
          </div>
          <div class="col-5 align-self-end">
            <img src="assets/images/profile-img.png" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div>
          <a routerLink="/">
            <div class="avatar-md profile-user-wid mb-4">
              <span class="avatar-title rounded-circle bg-light">
                <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34" />
              </span>
            </div>
          </a>
        </div>
        <div class="p-2">
          <form class="form-horizontal" (ngSubmit)="onRegister()">
            <ngb-alert type="danger" *ngIf="error2" [dismissible]="false">{{
              error2
              }}</ngb-alert>

            <div class="row" style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  ">
              <div class="col-lg-6" style="border-right: 1px solid #ebe2e2">
                <h5 style="margin-bottom: 1rem">Basic Information</h5>
                <div class="mb-3">
                  <label for="firstname">First Name</label>
                  <input type="text" class="form-control" name="firstname" #firstname="ngModel"
                    [(ngModel)]="registrationForm.firstname"
                    [class.is-invalid]="firstname.invalid &amp;&amp; firstname.touched" placeholder="Enter first name"
                    required />
                  <div *ngIf="firstname.invalid &amp;&amp; firstname.touched">
                    <small *ngIf="firstname.errors?.required" class="text-danger">First name is Required</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="email">Last Name</label>
                  <input type="text" class="form-control" name="lastname" #lastname="ngModel"
                    [(ngModel)]="registrationForm.lastname"
                    [class.is-invalid]="lastname.invalid &amp;&amp; lastname.touched" placeholder="Enter last name"
                    required />
                  <div *ngIf="lastname.invalid &amp;&amp; lastname.touched">
                    <small *ngIf="lastname.errors?.required" class="text-danger">Last name is Required</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="email">Email</label>
                  <input type="email" class="form-control" name="email" #email="ngModel"
                    [(ngModel)]="registrationForm.email" [class.is-invalid]="email.invalid &amp;&amp; email.touched"
                    placeholder="Enter email" required />
                  <div *ngIf="email.invalid &amp;&amp; email.touched">
                    <small *ngIf="email.errors?.required" class="text-danger">Email is Required</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="email">Phone</label>
                  <input type="text" class="form-control" name="phone" #phone="ngModel"
                    [(ngModel)]="registrationForm.phone" [class.is-invalid]="phone.invalid &amp;&amp; phone.touched"
                    placeholder="Enter phone number" required />
                  <div *ngIf="phone.invalid &amp;&amp; phone.touched">
                    <small *ngIf="phone.errors?.required" class="text-danger">Phone Number is Required</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input type="password" class="form-control"
                      [class.is-invalid]="password.invalid &amp;&amp; password.touched" name="password"
                      #password="ngModel" [(ngModel)]="registrationForm.password" placeholder="Enter password"
                      required />

                    <button class="btn btn-light ms-0" type="button" id="password-addon">
                      <i class="mdi mdi-eye-outline"></i>
                    </button>

                    <div *ngIf="password.invalid &amp;&amp; password.touched">
                      <small *ngIf="password.errors?.required" class="text-danger">Password is Required</small>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="confirmPassword">Confirm Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input type="password" class="form-control" name="confirmPassword" #confirmPassword="ngModel"
                      [(ngModel)]="registrationForm.confirmPassword"
                      [class.is-invalid]="confirmPassword.invalid &amp;&amp; confirmPassword.touched"
                      placeholder="Confirm Password" required />

                    <button class="btn btn-light ms-0" type="button" id="password-addon">
                      <i class="mdi mdi-eye-outline"></i>
                    </button>

                    <div *ngIf="confirmPassword.invalid &amp;&amp; confirmPassword.touched">
                      <small *ngIf="confirmPassword.errors?.required" class="text-danger">Password Does not match is
                        Required</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <h5 style="margin-bottom: 1rem">Residential Information</h5>

                <div class="mb-3">
                  <label for="email">Country</label>
                  <ng-select appearance="outline" [searchable]="false" [clearable]="false" name="country"
                    #country="ngModel" [(ngModel)]="registrationForm.country" [items]="countryList" bindLabel="label"
                    bindValue="value" placeholder="Selct Country"
                    [class.is-invalid]="country.invalid &amp;&amp; country.touched"></ng-select>
                  <div *ngIf="country.invalid &amp;&amp; country.touched">
                    <small *ngIf="country.errors?.required" class="text-danger">Country is Required</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="email">State</label>
                  <ng-select appearance="outline" [searchable]="false" [clearable]="false" name="state" #state="ngModel"
                    [(ngModel)]="registrationForm.state" [items]="stateList" bindLabel="label" bindValue="value"
                    placeholder="Selct state" [class.is-invalid]="state.invalid &amp;&amp; state.touched"></ng-select>
                  <div *ngIf="state.invalid &amp;&amp; state.touched">
                    <small *ngIf="state.errors?.required" class="text-danger">State is Required</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="email">LGA</label>
                  <ng-select appearance="outline" [searchable]="false" [clearable]="false" name="lga" #lga="ngModel"
                    [(ngModel)]="registrationForm.lga" [items]="lgaList" bindLabel="label" bindValue="value"
                    placeholder="Selct lga" [class.is-invalid]="lga.invalid &amp;&amp; lga.touched"></ng-select>
                  <div *ngIf="lga.invalid &amp;&amp; lga.touched">
                    <small *ngIf="lga.errors?.required" class="text-danger">LGA is Required</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="email">City</label>
                  <input type="text" class="form-control" name="city" #city="ngModel"
                    [(ngModel)]="registrationForm.city" [class.is-invalid]="city.invalid &amp;&amp; city.touched"
                    placeholder="Enter city" required />
                  <div *ngIf="city.invalid &amp;&amp; city.touched">
                    <small *ngIf="city.errors?.required" class="text-danger">City is Required</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="email">Address</label>
                  <input type="text" class="form-control" name="address" #address="ngModel"
                    [(ngModel)]="registrationForm.address"
                    [class.is-invalid]="address.invalid &amp;&amp; address.touched" placeholder="Enter address"
                    required />
                  <div *ngIf="address.invalid &amp;&amp; address.touched">
                    <small *ngIf="address.errors?.required" class="text-danger">Address is Required</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3 d-grid">
              <button *ngIf="!isLoading2" class="btn btn-primary" type="submit">
                Register
              </button>
              <button *ngIf="isLoading2" class="btn btn-primary" type="submit">
                Registering <span><i class="bx bx-loader bx-spin"></i></span>
              </button>
            </div>

            <div class="mt-4 text-center">
              Have an account?<a (click)="showAuthModal(loginModal)" style="margin-left: 0.5rem; cursor: pointer"
                class="text-muted">Login</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal to request unavailable products -->
<div class="modal fade" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="orderModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="orderModalLabel">Order Product</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form style="display: flex; flex-direction: column; gap: 20px;">
          <div class="form-group">
            <label for="fullName" style="font-family: Nunito Sans, sans-serif;">Full Name</label>
            <input type="text" class="form-control" id="fullName" placeholder="Enter full name"
              [(ngModel)]="user.fullName" name="fullName">
          </div>
          <div class="form-group">
            <label for="email" style="font-family: Nunito Sans, sans-serif;">Email</label>
            <input type="email" class="form-control" id="email" placeholder="Enter email" [(ngModel)]="user.email"
              name="email">
          </div>
          <div class="form-group">
            <label for="phoneNumber" style="font-family: Nunito Sans, sans-serif;">Phone Number</label>
            <input type="text" class="form-control" id="phoneNumber" placeholder="Enter phone number"
              [(ngModel)]="user.phoneNumber" name="phoneNumber">
          </div>
          <div class="form-group">
            <label for="productName" style="font-family: Nunito Sans, sans-serif;">Product Name</label>
            <input type="text" class="form-control" id="productName" placeholder="Enter product name"
              [(ngModel)]="user.productName" name="productName">
          </div>
          <div class="form-group">
            <label for="quantity" style="font-family: Nunito Sans, sans-serif;">Quantity</label>
            <input type="number" class="form-control" id="quantity" placeholder="Enter quantity"
              [(ngModel)]="user.quantity" name="quantity">
          </div>
          <div class="form-group">
            <label for="address" style="font-family: Nunito Sans, sans-serif;">Shipping Address</label>
            <input type="text" class="form-control" id="address" placeholder="Enter shipping address"
              [(ngModel)]="user.deliveryAddress" name="address">
          </div>
          <div class="form-group">
            <label for="description" style="font-family: Nunito Sans, sans-serif;">Additional Information</label>
            <textarea id="description" placeholder="Additional Information...." name="description" rows="4"
              class="form-control" [(ngModel)]="user.additionalInformation"></textarea>
          </div>

          <div style="display: flex; gap: 10px;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-submit" (click)="submitManualOrder()">Submit Request</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

<div class="vertical-menu">
  <!-- Scrollable area for the sidebar -->
  <ngx-simplebar class="h-100" #componentRef>
    <!-- Content template based on condensed or expanded state -->
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->